import React from 'react'

import Header from './header'
import Footer from './footer'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import CookieConsent from "react-cookie-consent";
import '../assets/scss/main.scss'
import '../assets/css/edgeforecaster.css'

const BASE_API = "https://o39pf2e866.execute-api.ap-southeast-2.amazonaws.com/default/"

class Layout extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
      super(props);     
      const { cookies } = props;
      this.state = {
        cookieConsent: cookies.get('cookieConsent') || false,
        details: cookies.get('details') || false,
      };
      this.changeCookie = this.changeCookie.bind(this)
      this.registerForNewsletter = this.registerForNewsletter.bind(this)      
  }  
  changeCookie(cookieName, newState) {
      const { cookies } = this.props;
      cookies.set(cookieName, newState, { path: '/' });
      this.setState({ cookieName:newState });      
      
  }  
  registerForNewsletter(email){            
      var newsrego = {
        emailaddress: email,
        action: 'subscribe'
      };      
      
      let headers = new Headers();
      headers.append('Content-Type', 'text/html');    
      headers.append('Origin','*');
      var requestConfig = {
          method: 'POST',
          body: JSON.stringify(newsrego),
          // mode: 'no-cors',
          mode: "cors",
          cache: "no-cache",
          headers: headers
      };
      fetch(BASE_API + 'usermgmt_newslettersubscriptions', requestConfig).then(response => response.json()            
      ).then(data => {
          console.log(data['body']['message']);
      }).catch(error => {
          console.log(error);
      });

  }
  componentDidMount() {
    
  }
  render() {
    var { location, children } = this.props;    
    if(location ==='/'){ location = 'EdgeForecaster'; }
        
    return(
      <React.Fragment>        
          <div className='baselayout'>
            <div id="page-wrapper">
              <Header auth={this.props.allCookies} menuLinks={this.props.data.data.site.siteMetadata.menuLinks} siteTitle="EdgeForecaster" />
              {children}
              <Footer pagewidth={this.props.pagewidth} newsletter={this.props.allCookies.newsletter} changeCookie={this.changeCookie} registerForNewsletter={this.registerForNewsletter}/>
              <CookieConsent
                        location="bottom"
                        buttonText="Accept"
                        declineButtonText="Decline"
                        buttonStyle={{ color: "#ffffff", backgroundColor:"#4648ff", borderRadius:"0.5vmin"}}
                        cookieName="cookie_googana"
                        onAccept={() => {
                            this.changeCookie('cookieConsent', {'state':true, 'timestamp':moment().format()})
                            this.changeCookie('cookie_googtag', true)
                            this.changeCookie('cookie_fbpix', true)            
                        }}>
                            This site uses cookies to enhance user experience. 
              </CookieConsent>
            </div>
          </div>        
      </React.Fragment>
    )
  }
}

export default withCookies(Layout)
