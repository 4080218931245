import React from "react";
import { SiTiktok,SiTwitter,SiFacebook,SiMedium,SiYoutube, SiTelegram} from "react-icons/si";
import { BsEnvelopeFill } from "react-icons/bs";
import { AiFillInstagram, AiFillMediumCircle } from "react-icons/ai";

function Socials() {
  return (
    <div>
      <div className="socials">
        <ul className="icons">
            <li>
                <a href="https://twitter.com/EdgeForecaster" className="icon brands alt">
                <SiTwitter />
                <span className="label">Twitter</span>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/edgeforecaster" className="icon brands alt">
                <SiFacebook />
                <span className="label">Facebook</span>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCsIiFhAbQ4bit8xrSmBliDQ" className="icon brands alt">
                <SiYoutube />
                <span className="label">YouTube</span>
                </a>
            </li>
            <li>
                <a href="	https://medium.com/@edgeforecaster" className="icon solid alt">
                {/* <AiFillMediumCircle /> */}
                <SiMedium />
                <span className="label">Medium</span>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/edgeforecaster/" className="icon brands alt">
                  <AiFillInstagram />
                  <span className="label">Instagram</span>
                </a>
            </li>
            <li>        
                <a href="https://www.tiktok.com/@edgeforecaster" className="icon brands alt">
                <SiTiktok />
                <span className="label">TikTok</span>
                </a>
            </li>
            <li>
                <a href="https://t.me/edgeforecaster" className="icon solid alt">
                <SiTelegram />
                <span className="label">Telegram</span>
                </a>
            </li>
        </ul>
      </div>
    </div>
  )
}

export default Socials;