import React from 'react'
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Socials from './socials'
// import logo from '../assets/images/baselogo.svg'
import logo from '../assets/images/baselogo_white.png'

class Footer extends React.Component {
  state = {
    email: '',
    newsSignup: false,
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = (event) => {
    event.preventDefault()
    this.props.registerForNewsletter(this.state.email)
    this.setState({ newsSignup: true })
    this.props.changeCookie('newsletter', true)
  }
  componentDidMount() {
    if (this.props.newsletter) {
      this.setState({ newsSignup: true })
    }
  }

  render() {
    var main_footer = (
      <div className="footer-container">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid container spacing={0} className="sitemap">
              <Grid item xs={3}>
                <Link to="/platform">
                  <div className="sitemap_item">Platform</div>
                </Link>
                <Link to="/features">
                  <div className="sitemap_subitem">Features</div>
                </Link>
                <Link to="/mobileapp">
                  <div className="sitemap_subitem">Mobile App</div>
                </Link>
                <Link to="/plans">
                  <div className="sitemap_subitem">Pricing</div>
                </Link>
                <Link to="/offers">
                  <div className="sitemap_subitem">Offers</div>
                </Link>
                <Link to="/accuracy">
                  <div className="sitemap_subitem">Accuracy</div>
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link to="/edgeuniversity">
                  <div className="sitemap_item">Edge University</div>
                </Link>
                <Link to="/faqs">
                  <div className="sitemap_subitem">F.A.Q.s</div>
                </Link>
                <Link to="/news">
                  <div className="sitemap_subitem">News</div>
                </Link>
                <Link to="/roadmap">
                  <div className="sitemap_subitem">Roadmap</div>
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link to="/profile">
                  <div className="sitemap_item">Login</div>
                </Link>
                <a href="https://app.edgeforecaster.com">
                  <div className="sitemap_subitem">My EdgeForecaster</div>
                </a>
                <Link to="/signup">
                  <div className="sitemap_subitem">Signup</div>
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link to="/contact">
                  <div className="sitemap_item">Contact</div>
                </Link>
                <Link to="/privacy">
                  <div className="sitemap_subitem">Privacy Policy</div>
                </Link>
                <Link to="/terms">
                  <div className="sitemap_subitem">Terms &amp; Conditions</div>
                </Link>
                <Link to="/careers">
                  <div className="sitemap_subitem">Careers</div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={3} className="newsletter_section">
              <Grid item xs={12}>
                <h1 className="newsletter_heading">Sign up for Newsletter</h1>
              </Grid>
              <Grid item xs={12}>
                {this.state.newsSignup ? (
                  <div className="newsletter_thankyou">
                    <span>
                      Thank-you for signing up for the EdgeForecaster
                      Newsletter.
                    </span>
                  </div>
                ) : (
                  <form
                    id="newsletter-form"
                    method="post"
                    onSubmit={this.handleSubmit}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="form-group">
                          <input
                            type="text"
                            id="emailinput"
                            placeholder="Email..."
                            className="newsletter_input"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <button
                          type="submit"
                          className="newsletter_submit_button"
                        >
                          Submit
                        </button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Link to="/">
              <img className="footer_logo" src={logo} alt="EdgeForecaster" />
            </Link>
            <div className="details">
              <Socials />
              <div className="copyright">
                © {new Date().getFullYear()}{' '}
                <a href="https://edgeForecaster.com/">EdgeForecaster</a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
    var mobile_footer = (
      <div className="footer-container">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid container spacing={0} className="sitemap">
              <Grid item xs={6}>
                <Link to="/platform">
                  <div className="sitemap_item">Platform</div>
                </Link>
                <Link to="/features">
                  <div className="sitemap_subitem">Features</div>
                </Link>
                <Link to="/mobileapp">
                  <div className="sitemap_subitem">Mobile App</div>
                </Link>
                <Link to="/plans">
                  <div className="sitemap_subitem">Pricing</div>
                </Link>
                <Link to="/offers">
                  <div className="sitemap_subitem">Offers</div>
                </Link>
                <Link to="/accuracy">
                  <div className="sitemap_subitem">Accuracy</div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link to="/edgeuniversity">
                  <div className="sitemap_item">Edge University</div>
                </Link>
                <Link to="/faqs">
                  <div className="sitemap_subitem">F.A.Q.s</div>
                </Link>
                <Link to="/news">
                  <div className="sitemap_subitem">News</div>
                </Link>
                <Link to="/roadmap">
                  <div className="sitemap_subitem">Roadmap</div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link to="/profile">
                  <div className="sitemap_item">Login</div>
                </Link>
                <a href="https://app.edgeforecaster.com">
                  <div className="sitemap_subitem">My EdgeForecaster</div>
                </a>
                <Link to="/signup">
                  <div className="sitemap_subitem">Signup</div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link to="/contact">
                  <div className="sitemap_item">Contact</div>
                </Link>
                <Link to="/privacy">
                  <div className="sitemap_subitem">Privacy Policy</div>
                </Link>
                <Link to="/terms">
                  <div className="sitemap_subitem">Terms &amp; Conditions</div>
                </Link>
                <Link to="/careers">
                  <div className="sitemap_subitem">Careers</div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={3} className="newsletter_section">
                  <Grid item xs={12}>
                    <h1 className="newsletter_heading">
                      Sign up for Newsletter
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.newsSignup ? (
                      <div className="newsletter_thankyou">
                        <span>
                          Thank-you for signing up for the EdgeForecaster
                          Newsletter.
                        </span>
                      </div>
                    ) : (
                      <form
                        id="newsletter-form"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="emailinput"
                                    placeholder="Email..."
                                    className="newsletter_input"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={4} />
                              <Grid item xs={8}>
                                <button
                                  type="submit"
                                  className="newsletter_submit_button"
                                >
                                  Submit
                                </button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Link to="/">
                  <img
                    className="footer_logo"
                    src={logo}
                    alt="EdgeForecaster"
                  />
                </Link>
                <div className="details">
                  <Socials />
                  <div className="copyright">
                    © {new Date().getFullYear()}{' '}
                    <a href="https://edgeForecaster.com/">EdgeForecaster</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )

    return (
      <div className="footer">
        <hr
          style={{
            border: '1px solid #ffffff',
            marginBottom: '1vmin',
            marginTop: '2vmin',
            maxWidth: '100vw',
          }}
        />
        {this.props.pagewidth ? mobile_footer : main_footer}
      </div>
    )
  }
}

export default Footer
